import { Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import CameraScan from './CameraScan';
import Result from './Result';
import { NotFound } from '../common/NotFound';

function Auth({ colorType }) {
  return (
    <Routes>
      <Route path="/" element={<CameraScan colorType="light" />} />
      <Route path="/scan-result" element={<Result colorType={colorType} />} />
      <Route path="*" element={<NotFound colorType={colorType} />} />
    </Routes>
  );
}

Auth.propTypes = {
  colorType: PropTypes.string.isRequired,
};

export default Auth;
