import { CodeValidation as ClgCodeValidation, Flex } from '@ebay-certilogo/design-system-web';
import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const CodeValidation = styled(ClgCodeValidation)`
  text-align: center;
  .container {
    justify-content: center;
  }
`;

export const Header = styled(Flex)`
  flex-direction: column;
  align-items: center;
`;
