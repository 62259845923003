import { defineMessages } from 'react-intl';

const card = defineMessages({
  title: {
    id: 'confirm_email.title1',
    defaultMessage: 'Verify your identity',
  },
  text1: {
    id: 'confirm_email.text1',
    defaultMessage: 'Type the code sent to',
  },
  changeEmail: {
    id: 'new_login.edit_email',
    defaultMessage: 'Change Email',
  },
});

const errors = {
  pin: defineMessages({
    expired: {
      id: 'code_login.errors.expired',
      defaultMessage: 'This code has expired, click Resend code to get a new one',
    },
    invalid: {
      id: 'code_login.errors.invalid',
      defaultMessage: 'Invalid code',
    },
    too_many_requests: {
      id: 'code_login.errors.too_many_requests',
      defaultMessage: 'Too many attempts. Try again later or request a new code.',
    },
  }),
};

const defaultMessages = defineMessages({
  title: {
    id: 'login_new.title1',
    defaultMessage: 'Login',
  },
  description: {
    id: 'login_new.text1',
    defaultMessage: 'You’re almost there, please log in to proceed',
  },
});

export default {
  ...defaultMessages,
  card,
  errors,
};
