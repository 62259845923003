import styled from 'styled-components';
import { Flex, Button, Div, Text } from '@ebay-certilogo/design-system-web';
import { getField } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';
import { color, margin } from '@ebay-certilogo/design-system-web/dist/shared/styles';

export const Wrapper = styled(Flex)`
  flex-direction: column;
`;

export const Header = styled(Flex)`
  flex-direction: column;
  align-items: center;
`;

export const SocialButton = styled(Button)`
  min-height: 50px;
  max-height: 50px;
  padding: 0;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const AppleButton = styled(SocialButton).attrs({
  colorType: 'light',
})`
  border: 1px solid ${(props) => getField('button.color', props)};
`;

export const GoogleButton = styled(SocialButton).attrs({
  colorType: 'dark',
})`
  background-color: ${({ disabled }) => (disabled ? color.lightGrey : '#4285f4')};
`;

export const FacebookButton = styled(SocialButton).attrs({
  colorType: 'dark',
})`
  background-color: #1877f2;
`;

export const EbayButton = styled(SocialButton).attrs({
  colorType: 'light',
})`
  background-color: #ffffff;
  border: 1px solid;
`;

export const SocialIcon = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  ${margin('s', 'left')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

export const LoginInputWrapper = styled(Div)`
  position: relative;
  ${margin('m', 'vertical')}
`;
