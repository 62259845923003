import { Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CardClg } from 'components/common/CardClg';
import Login from './Login';
import CodeCheck from './CodeCheck';

function Auth({ colorType }) {
  return (
    <Routes>
      <Route element={<CardClg />}>
        <Route index element={<Login colorType={colorType} />} />
        <Route path="/code-check" element={<CodeCheck colorType={colorType} />} />
      </Route>
    </Routes>
  );
}

Auth.propTypes = {
  colorType: PropTypes.string.isRequired,
};

export default Auth;
