/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { getLocale } from './getLocale';

export function LanguageProvider({ messages: _messages, children }) {
  const locale = getLocale();
  const messages = _messages[locale];

  return (
    <IntlProvider locale={locale} key={locale} messages={messages || {}} textComponent="span">
      {React.Children.only(children)}
    </IntlProvider>
  );
}

LanguageProvider.defaultProps = {
  messages: {},
};

LanguageProvider.propTypes = {
  messages: PropTypes.object,
  children: PropTypes.element.isRequired,
};

export default { LanguageProvider };
