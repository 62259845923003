import * as PropTypes from 'prop-types';
import { createContext, useState, useMemo } from 'react';

export const UiContext = createContext();

function UiProvider({ children }) {
  const [colorType, setColorType] = useState('dark');
  const [prevColorType, setPrevColorType] = useState('dark');

  const value = useMemo(() => {
    const handleSetColorType = (type) => {
      setPrevColorType(colorType);
      setColorType(type);
    };

    const handleResetColorType = () => {
      setColorType(prevColorType);
    };

    return {
      colorType,
      setColorType: handleSetColorType,
      resetColorType: handleResetColorType,
    };
  }, [colorType, prevColorType, setColorType, setPrevColorType]);

  return <UiContext.Provider value={value}>{children}</UiContext.Provider>;
}

UiProvider.defaultProps = {
  children: null,
};

UiProvider.propTypes = {
  children: PropTypes.node,
};

export { UiProvider };
