import { Text, FormFormik, Heading, Button } from '@ebay-certilogo/design-system-web';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCodeLoginRequest } from '../hooks/useCodeLoginRequest';
import { Header, Wrapper } from './styles';
import messages from './messages';

const { TextInput } = FormFormik;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(<FormattedMessage {...messages.errors.email.format} />)
    .required(<FormattedMessage {...messages.errors.required} />),
});

function Login({ colorType }) {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [error, setError] = useState(null);

  const [email, setEmail] = useState(null);

  const codeLoginRequest = useCodeLoginRequest();
  const { data, isPending, isSuccess } = codeLoginRequest;

  const handleSubmit = async (values) => {
    setEmail(values?.email);
    codeLoginRequest.mutate({ email: values?.email });
  };

  useEffect(() => {
    if (isSuccess) {
      return navigate('/auth/code-check', { state: { email, timeToWait: data?.timeToWait } });
    }
    return () => {};
  }, [navigate, isSuccess, email]);

  useEffect(() => {
    const searchParamsError = searchParams.get('error');
    if (searchParamsError) {
      setError(searchParamsError);
    }
    return () => {};
  }, [searchParams]);

  useEffect(() => {
    if (codeLoginRequest.error) {
      setError(codeLoginRequest?.error?.response?.data?.error);
    }
    return () => {};
  }, [codeLoginRequest.error]);

  return (
    <Wrapper>
      <Header>
        <Heading type="h1" colorType={colorType} marginBottom="l">
          <FormattedMessage {...messages.title} />
        </Heading>
      </Header>
      {!!error && (
        <Text colorType={colorType} themeField="form.errorColor" size="xs" marginBottom="m">
          <FormattedMessage
            {...messages.errors.email[error]}
            values={codeLoginRequest?.error?.response?.data}
          />
        </Text>
      )}
      <Formik
        initialValues={{ email: null }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ setFieldError, errors, values }) => {
          if (isEmpty(errors) && (error || {}).email)
            setFieldError('email', <FormattedMessage {...messages.errors.required} />);
          return (
            <Form noValidate>
              <Field
                name="email"
                component={TextInput}
                colorType={colorType}
                autoCapitalize="none"
                type="email"
                label={<FormattedMessage {...messages.emailLabel} />}
              />
              <Button
                type="submit"
                colorType={colorType}
                disabled={!values.email}
                backgroundOpacity={!values.email && 0.92}
                style={{ padding: 0 }}
                loading={isPending}
                marginTop="xl"
              >
                <FormattedMessage {...messages.submit} />
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Wrapper>
  );
}

Login.defaultProps = {
  colorType: 'light',
  intl: () => {},
};

Login.propTypes = {
  colorType: PropTypes.string,
  intl: PropTypes.object,
};

export default Login;
