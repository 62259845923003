import { createBrowserRouter } from 'react-router-dom';
import { NotFound } from 'components/common/NotFound';
import Scan from './components/Scan';
import PrivateRoutes from './PrivateRoutes';
import Auth from './components/Auth';
import Layout from './components/Layout';

const colorType = 'dark';

const router = createBrowserRouter([
  {
    element: <Layout colorType={colorType} />,
    children: [
      {
        element: <PrivateRoutes />,
        children: [
          {
            path: '/*',
            element: <Scan colorType={colorType} />,
          },
        ],
      },
      {
        path: '/auth/*',
        element: <Auth colorType={colorType} />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]);

export default router;
