import { useEffect, useState } from 'react';
import { Text, Heading, Button, Loader, Div } from '@ebay-certilogo/design-system-web';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useCountDown } from 'ahooks';
import { useAuth } from 'providers/AuthProvider';
import messages from './messages';
import { CodeValidation, Header } from './styles';
import ResendCode from './ResendCode';
import { useCodeLoginCheckRequest } from '../hooks/useCodeLoginCheckRequest';

function CodeLogin({ colorType }) {
  const { login } = useAuth();
  const navigate = useNavigate();
  const defaultCodeLoginCheckData = {
    email: null,
    error: null,
    timeToWait: null,
  };
  const [codeLoginCheckData, setCodeLoginCheckData] = useState(defaultCodeLoginCheckData);
  const isError = !!codeLoginCheckData?.error;

  const {
    state: { email, timeToWait },
  } = useLocation();

  const codeLoginCheckRequest = useCodeLoginCheckRequest();
  const { isPending } = codeLoginCheckRequest;

  const handleChangeEmail = () => {};
  const [value, setValue] = useState('');

  const handleOnChange = (val) => {
    setValue(val);
  };

  useEffect(() => {
    if (value?.length === 4) {
      codeLoginCheckRequest.mutate({ email, pin: value });
      setValue('');
    }
  }, [value, email]);

  useEffect(() => {
    if (codeLoginCheckRequest?.error) {
      setCodeLoginCheckData(codeLoginCheckRequest?.error?.response?.data);
    }
  }, [codeLoginCheckRequest?.error]);

  useEffect(() => {
    if (codeLoginCheckRequest?.data) {
      setCodeLoginCheckData(codeLoginCheckRequest?.data);
      login(codeLoginCheckRequest.data);
      navigate('/');
    }
  }, [codeLoginCheckRequest.data, login, navigate]);

  useCountDown({
    leftTime: () =>
      // eslint-disable-next-line no-unsafe-optional-chaining
      codeLoginCheckData?.timeToWait ? codeLoginCheckData?.timeToWait * 1000 : null,
    onEnd: () => setCodeLoginCheckData(defaultCodeLoginCheckData),
  });

  return (
    <Div textAlign="center">
      <Header>
        <Heading type="h1" colorType={colorType} marginBottom="m">
          <FormattedMessage {...messages.title} />
        </Heading>
        <Text colorType={colorType} marginBottom="m" textAlign="center">
          <FormattedMessage {...messages.description} />
        </Text>
      </Header>
      {isPending ? (
        <Loader colorType={colorType} style={{ height: '40px', width: '40px' }} />
      ) : (
        <>
          <Heading type="h2" colorType={colorType} textAlign="center" marginBottom="m">
            <FormattedMessage {...messages.card.title} />
          </Heading>
          <Text colorType={colorType} textAlign="center">
            <FormattedMessage {...messages.card.text1} />
          </Text>
          <Text weight="extrabold" colorType={colorType} textAlign="center" marginBottom="m">
            {email}
          </Text>
          {codeLoginCheckData?.error !== 'too_many_requests' && (
            <CodeValidation
              isError={isError}
              colorType={colorType}
              isInputNum
              shouldAutoFocus
              onChange={handleOnChange}
              value={value}
              disa
            />
          )}
          {codeLoginCheckData?.error && (
            <Text themeField="form.errorColor" textAlign="center" marginTop="m">
              <FormattedMessage
                {...get(messages, `errors.pin.${codeLoginCheckData?.error}`)}
                values={{ timeToWait: codeLoginCheckData.TimeToWait }}
              />
            </Text>
          )}
          <ResendCode
            colorType={colorType}
            email={email}
            timeToWait={timeToWait}
            onSubmit={() => setCodeLoginCheckData(defaultCodeLoginCheckData)}
          />
          <Button
            buttonType="link"
            colorType={colorType}
            arrow={false}
            noMargin
            onClick={handleChangeEmail}
            as={Link}
            to="/auth"
          >
            <FormattedMessage {...messages.card.changeEmail} />
          </Button>
        </>
      )}
    </Div>
  );
}

CodeLogin.defaultProps = {
  colorType: 'light',
};

CodeLogin.propTypes = {
  colorType: PropTypes.string,
};

export default CodeLogin;
