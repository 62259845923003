import { defineMessages } from 'react-intl';

const defaultMessages = defineMessages({
  caption: {
    id: 'cameraScan.caption',
    defaultMessage: 'Inquadra il QR code che si trova nello Smart TAG Certilogo',
  },
});

export default {
  ...defaultMessages,
};
