import styled from 'styled-components';
import { Flex } from '@ebay-certilogo/design-system-web';
import {
  secondaryColor,
  fontFamily,
} from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';
import { fontSize, margin, padding } from '@ebay-certilogo/design-system-web/dist/shared/styles';

export const Wrapper = styled(Flex)`
  flex-direction: column;
  background-color: ${secondaryColor};
  border-radius: 6px;
  margin-left: -10px;
  margin-right: -10px;
  ${padding('m')}
  ${margin('l', 'top')}
  font-family: ${fontFamily};
  ${fontSize()}
`;

export default { Wrapper };
