import { Exit } from '@ebay-certilogo/design-system-web/dist/components/icons';
import { useAuth } from 'providers/AuthProvider';

export function LogoutButton() {
  const { authToken, logout } = useAuth();

  return (
    authToken && (
      <Exit
        onClick={() => {
          if (authToken) logout();
        }}
        style={{ margin: '0 10px', padding: '5px' }}
      />
    )
  );
}

export default LogoutButton;
