import PropTypes from 'prop-types';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { Button, Div, Text } from '@ebay-certilogo/design-system-web';
import { FormattedMessage } from 'react-intl';
import Svg from 'react-inlinesvg';
import { CardClg } from 'components/common/CardClg';
import messages from './messages';
import koIcon from './ko-icon.svg';
import okIcon from './ok-icon.svg';

function Result({ colorType }) {
  const { state } = useLocation();

  if (!state) {
    return <Navigate to="/" />;
  }

  const { data, isError } = state;

  if (isError) {
    return (
      <CardClg>
        <Div textAlign="center">
          <Text size="l" weight="extrabold" colorType={colorType} marginBottom="l">
            <FormattedMessage {...messages.error.title} />
          </Text>
          <Text colorType={colorType} marginBottom="l">
            <FormattedMessage {...messages.error.description} />
          </Text>
          <Button colorType={colorType} as={Link} to="/">
            <FormattedMessage {...messages.newScanButton} />
          </Button>
        </Div>
      </CardClg>
    );
  }

  return (
    data && (
      <CardClg>
        <Div textAlign="center">
          {data?.primaryBrand && (
            <Text weight="extrabold" colorType={colorType} marginBottom="l">
              {data.primaryBrand}
            </Text>
          )}
          <Svg src={data.status === 'donated' ? okIcon : koIcon} />
          {messages.reasons[data.reason]?.title && (
            <Text size="l" weight="extrabold" colorType={colorType} marginVertical="l">
              <FormattedMessage {...messages.reasons[data.reason].title} />
            </Text>
          )}
          {messages.reasons[data.reason]?.description && (
            <Text size="m" colorType={colorType} marginBottom="l">
              <FormattedMessage {...messages.reasons[data.reason].description} />
            </Text>
          )}
          <Button colorType={colorType} as={Link} to="/">
            <FormattedMessage {...messages.newScanButton} />
          </Button>
        </Div>
      </CardClg>
    )
  );
}

Result.propTypes = {
  colorType: PropTypes.string.isRequired,
};

export default Result;
