import { RouterProvider } from 'react-router-dom';
import { GlobalStyle } from '@ebay-certilogo/design-system-web';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from 'providers/AuthProvider';
import { UiProvider } from 'providers/UiProvider';
import ThemeProvider from './ThemeProvider';
import router from './router';
import { translationMessages } from './i18n';
import { LanguageProvider } from './components/LanguageProvider';

const queryClient = new QueryClient();

function App() {
  return (
    <AuthProvider>
      <UiProvider>
        <QueryClientProvider client={queryClient}>
          <LanguageProvider messages={translationMessages}>
            <ThemeProvider>
              <RouterProvider router={router} />
              <GlobalStyle />
            </ThemeProvider>
          </LanguageProvider>
        </QueryClientProvider>
      </UiProvider>
    </AuthProvider>
  );
}

export default App;
