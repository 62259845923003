import { defineMessages } from 'react-intl';

const errorsEmail = defineMessages({
  default: {
    id: 'login_signup_error2.text1',
    defaultMessage: 'Your email seems invalid',
  },
  format: {
    id: 'login_signup_error1.text1',
    defaultMessage: 'Error, this is not a valid format',
  },
  too_many_requests: {
    id: 'code_login.request.errors.too_many_requests',
    defaultMessage: 'Wait {timeToWait} seconds to request a new code',
  },
  not_authorized: {
    id: 'code_login.request.errors.not_authorized',
    defaultMessage: 'Not authorized',
  },
});

const errors = defineMessages({
  required: {
    id: 'form.field.error.required',
    defaultMessage: 'This is a required field',
  },
  email: errorsEmail,
});

export default defineMessages({
  submit: {
    id: 'login.submit',
    defaultMessage: 'Login',
  },
  title: {
    id: 'login.title1',
    defaultMessage: 'Login',
  },
  emailLabel: {
    id: 'signup.emailLabel',
    defaultMessage: 'Email aziendale',
  },
  errors,
});
