/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */
// const get = require('lodash/get');
// const browserLanguage = require('in-browser-language');

export const DEFAULT_LOCALE = 'en';

// const appLocales = ['de', 'en'];
export const appLocales = ['en', 'it'];

// const originalTranslationMessages = appLocales.reduce((acc, lang) => {
//   console.log(lang);

//   return {
//     ...acc,
//     // eslint-disable-next-line global-require
//     [lang]: require(`./translations/${lang}.json`),
//   };
// });

const originalTranslationMessages = {
  en: await import('./en.json'),
  it: await import('./it.json'),
};

if (!Intl.PluralRules) {
  await import('@formatjs/intl-pluralrules/polyfill');

  await import(`@formatjs/intl-pluralrules/locale-data/en`); // Add locale data for en
  await import(`@formatjs/intl-pluralrules/locale-data/it`); // Add locale data for it
  // appLocales.forEach((lang) => {
  //   // eslint-disable-next-line global-require
  //   require(`@formatjs/intl-pluralrules/dist/locale-data/${lang}`); // Add locale data for de
  // });
}

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, originalTranslationMessages[DEFAULT_LOCALE])
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE ? defaultFormattedMessages[key] : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

// export const translationMessages = appLocales.reduce((acc, lang) => ({
//   ...acc,
//   [lang]: formatTranslationMessages(lang, originalTranslationMessages[lang]),
// }));

export const translationMessages = {
  en: formatTranslationMessages('en', originalTranslationMessages.en.default),
  it: formatTranslationMessages('it', originalTranslationMessages.it.default),
};
