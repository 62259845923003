import PropTypes from 'prop-types';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import { composeTheme } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/utils';

const overrideTheme = { dark: { backgroundColor: 'transparent' } };

function ThemeProvider({ children }) {
  const theme = composeTheme('certilogo', overrideTheme);
  return <SCThemeProvider theme={theme}>{children}</SCThemeProvider>;
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;
