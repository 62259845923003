import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Text } from '@ebay-certilogo/design-system-web';
import { useCountDown } from 'ahooks';
import messages from '../messages';

function CountDownMessage({ colorType, timeToWait, error, onEnd }) {
  const [countdown] = useCountDown({
    leftTime: timeToWait * 1000,
    onEnd: () => onEnd(),
  });
  return (
    <Text colorType={colorType} marginVertical="m" textAlign="center">
      <FormattedMessage
        {...messages.errors[error]}
        values={{ timeToWait: (countdown / 1000).toFixed(0) }}
      />
    </Text>
  );
}

CountDownMessage.defaultProps = {
  colorType: 'light',
  error: null,
  timeToWait: null,
  onEnd: () => {},
};

CountDownMessage.propTypes = {
  colorType: PropTypes.string,
  error: PropTypes.string,
  timeToWait: PropTypes.number,
  onEnd: PropTypes.func,
};

export default CountDownMessage;
