import axios from 'axios';
import { join } from 'lodash';
import appConfig from './config';

const getHostname = (v) => {
  const version = v || 2;
  const splittedHost = window.location.host.split('.');
  if (splittedHost[0].includes('localhost') || splittedHost[0].includes('127.0.1')) {
    return appConfig.api.hostName[version];
  }
  splittedHost[0] = `https://${appConfig.api.hostDomain[version]}`;
  return join(splittedHost, '.');
};

const defaultOpts = {
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
};

const fetchWrapper = {
  get: async (path, params, opts) => {
    try {
      const response = await axios.get(
        path,
        { params },
        {
          ...defaultOpts,
          baseURL: getHostname(opts?.v),
          headers: { ...defaultOpts.headers, ...opts?.headers },
        },
      );
      return response.data;
    } catch (error) {
      console.error('GET Request Error:', error);
      throw error;
    }
  },

  post: async (path, data, opts) => {
    try {
      const response = await axios.post(path, data, {
        ...defaultOpts,
        baseURL: getHostname(opts?.v),
        headers: { ...defaultOpts.headers, ...opts?.headers },
      });
      return response.data;
    } catch (error) {
      console.error('POST Request Error:', error);
      throw error;
    }
  },

  put: async (path, data, opts) => {
    try {
      const response = await axios.put(path, data, {
        ...defaultOpts,
        baseURL: getHostname(opts?.v),
        headers: { ...defaultOpts.headers, ...opts?.headers },
      });
      return response.data;
    } catch (error) {
      console.error('PUT Request Error:', error);
      throw error;
    }
  },

  delete: async (path, opts) => {
    try {
      const response = await axios.delete(path, {
        ...defaultOpts,
        baseURL: getHostname(opts?.v),
        headers: { ...defaultOpts.headers, ...opts?.headers },
      });
      return response.data;
    } catch (error) {
      console.error('DELETE Request Error:', error);
      throw error;
    }
  },
};

export default fetchWrapper;
