import { Layout as ClgLayout, Div } from '@ebay-certilogo/design-system-web';
import Svg from 'react-inlinesvg';
import { useColorType } from 'hooks/useColorType';
import clgLogo from './clg-logo.svg';
import { LogoutButton } from './LogoutButton';

function Header() {
  const { colorType } = useColorType();
  return (
    <ClgLayout.Header
      colorType={colorType}
      left={<Div />}
      center={<Svg src={clgLogo} alt="logo" />}
      right={<LogoutButton />}
    />
  );
}

export default Header;
