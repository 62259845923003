import PropTypes from 'prop-types';
import { createContext, useState, useContext, useEffect, useMemo } from 'react';

const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const [authData, setAuthData] = useState(JSON.parse(localStorage.getItem('auth')));

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('auth'));
    if (data) {
      setAuthData(data);
    }
  }, []);

  const providerValue = useMemo(() => {
    const goToLogin = ({ search }) => {
      const url = new URL('/auth', window.location.origin);
      const params = new URLSearchParams(search);
      url.search = params.toString();
      window.location = url.pathname + url.search;
    };

    const login = (data) => {
      if (data?.roles?.includes('ROLE_DONATE_OPERATOR')) {
        localStorage.setItem('auth', JSON.stringify(data));
        setAuthData(data);
      } else {
        goToLogin({ search: { error: 'not_authorized' } });
      }
    };

    const logout = () => {
      localStorage.removeItem('auth');
      setAuthData(null);
      goToLogin();
    };
    return { authToken: authData?.token, authData, goToLogin, login, logout };
  }, [authData]);

  return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => useContext(AuthContext);
