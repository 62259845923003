import { ThemeProvider } from 'styled-components';
import { composeTheme } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/utils';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Wrapper } from './styles';

export function CardClg({ children }) {
  const colorTypeLoginWrapper = 'dark';

  const theme = composeTheme('card', {});
  return (
    <ThemeProvider theme={theme}>
      <Wrapper colorType={colorTypeLoginWrapper}>
        <Outlet />
        {children}
      </Wrapper>
    </ThemeProvider>
  );
}

CardClg.defaultProps = {
  children: null,
};

CardClg.propTypes = {
  children: PropTypes.node,
};

export default CardClg;
