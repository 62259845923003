import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Text, Modal } from '@ebay-certilogo/design-system-web';
import { useCodeLoginRequest } from 'components/Auth/hooks/useCodeLoginRequest';
import messages from './messages';
import CountDownMessage from './CountDownMessage';

function ResendCode({ colorType, email, timeToWait, onSubmit }) {
  const [innerTimeToWait, setInnerTimeToWait] = useState(timeToWait);
  const [submitted, setSubmitted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const codeLoginRequest = useCodeLoginRequest();
  const { isPending } = codeLoginRequest;

  const handleResendCode = () => {
    onSubmit();
    setSubmitted(true);
    codeLoginRequest.mutate({ email });
  };

  useEffect(() => {
    if (submitted && !isPending) {
      setIsModalOpen(true);
    }
  }, [isPending, submitted]);

  return (
    <>
      {innerTimeToWait ? (
        <CountDownMessage
          colorType={colorType}
          error="too_many_requests"
          timeToWait={innerTimeToWait}
          onEnd={() => setInnerTimeToWait(null)}
        />
      ) : (
        <Button
          buttonType="link"
          colorType={colorType}
          arrow={false}
          noMargin
          onClick={handleResendCode}
          loading={isPending}
        >
          <FormattedMessage {...messages.link} />
        </Button>
      )}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} close colorType={colorType}>
        <Text colorType={colorType} weight="extrabold" marginBottom="m" textAlign="center">
          <FormattedMessage {...messages.title} />
        </Text>
        <Text colorType={colorType} marginBottom="m" textAlign="center">
          <FormattedMessage {...messages.text1} values={{ email }} />
        </Text>
      </Modal>
    </>
  );
}

ResendCode.defaultProps = {
  colorType: 'light',
  timeToWait: null,
  onSubmit: () => {},
};

ResendCode.propTypes = {
  email: PropTypes.string.isRequired,
  colorType: PropTypes.string,
  timeToWait: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default ResendCode;
