import { Text } from '@ebay-certilogo/design-system-web';
import * as PropTypes from 'prop-types';

export function NotFound({ colorType }) {
  return <Text colorType={colorType}>Page Not Found</Text>;
}

NotFound.propTypes = {
  colorType: PropTypes.string.isRequired,
};

export default NotFound;
