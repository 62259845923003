import { Camera as _Camera, Div } from '@ebay-certilogo/design-system-web';
import styled from 'styled-components';
import Svg from 'react-inlinesvg';
import {
  primaryAccentColor,
  primaryColor,
} from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';

export const Camera = styled(_Camera)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  flex: 1;
  canvas {
    position: relative;
  }
`;

export const Overlay = styled(Div)`
  z-index: 3;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FrameWrapper = styled(Div)`
  position: relative;
  width: 280px;
  height: 280px;
  align-self: center;
  text-align: center;
`;

export const FrameSvg = styled(Svg).attrs({
  width: 280,
  height: 280,
  containerStyle: { width: 280, height: 280 },
})`
  color: ${({ active }) => (active ? primaryAccentColor : primaryColor)};
  height: 280px;
`;
