import browserLanguage from 'in-browser-language';
import get from 'lodash/get';
import { appLocales, DEFAULT_LOCALE } from 'src/i18n';

export const getLocale = () => {
  const urlParams = new URLSearchParams(get(window, 'location.search'));
  const qsLn = urlParams.get('ln');
  const extLn = appLocales.includes(qsLn) && qsLn;
  return extLn || browserLanguage.pick(appLocales, 'en') || DEFAULT_LOCALE;
};

export default {
  getLocale,
};
