import { defineMessages } from 'react-intl';

const reasons = {
  donated: defineMessages({
    title: {
      id: 'scanResult.reasons.donated.title',
      defaultMessage: 'Donazione registrata correttamente',
    },
  }),
  not_authentic: defineMessages({
    title: {
      id: 'scanResult.reasons.not_authentic.title',
      defaultMessage: 'Impossibile registrare la donazione',
    },
    description: {
      id: 'scanResult.reasons.fake.description',
      defaultMessage: 'Questo prodotto non è autentico',
    },
  }),
  legal_stolen: defineMessages({
    title: {
      id: 'scanResult.reasons.legal_stolen.title',
      defaultMessage: 'Impossibile registrare la donazione',
    },
    description: {
      id: 'scanResult.reasons.legal_stolen.description',
      defaultMessage: 'Questo prodotto presenta delle problematiche legali',
    },
  }),
  legal_blocked: defineMessages({
    title: {
      id: 'scanResult.reasons.legal_blocked.title',
      defaultMessage: 'Impossibile registrare la donazione',
    },
    description: {
      id: 'scanResult.reasons.legal_blocked.description',
      defaultMessage: 'Questo prodotto presenta delle problematiche legali',
    },
  }),
  not_sure: defineMessages({
    title: {
      id: 'scanResult.reasons.not_sure.title',
      defaultMessage: 'Non è possibile determinare l’autenticità di questo prodotto',
    },
    description: {
      id: 'scanResult.reasons.not_sure.description',
      defaultMessage: 'Questo prodotto presenta delle problematiche legali',
    },
  }),
  brand_not_covered: defineMessages({
    title: {
      id: 'scanResult.reasons.brand_not_covered.title',
      defaultMessage: 'Impossibile registrare la donazione',
    },
    description: {
      id: 'scanResult.reasons.brand_not_covered.description',
      defaultMessage: 'Questo Brand non aderisce al programma',
    },
  }),
};

const error = defineMessages({
  title: {
    id: 'scanResult.error.title',
    defaultMessage: 'Oops...',
  },
  description: {
    id: 'scanResult.error.description',
    defaultMessage: 'Quello che ti aspettavi non è stato trovato.',
  },
});

const defaultMessages = defineMessages({
  newScanButton: {
    id: 'scanResult.newScanButton',
    defaultMessage: 'Nuova scansione',
  },
});

export default {
  ...defaultMessages,
  reasons,
  error,
};
