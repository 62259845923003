import { useContext, useEffect } from 'react';
import { UiContext } from '../providers/UiProvider';

export function useColorType() {
  const { colorType, setColorType, resetColorType } = useContext(UiContext);

  useEffect(() => {
    return resetColorType;
  }, []);

  return { colorType, setColorType };
}

export default { useColorType };
