import { Outlet } from 'react-router-dom';
import { Layout as ClgLayout } from '@ebay-certilogo/design-system-web';
import PropTypes from 'prop-types';
import Header from 'components/common/Header';
import bg from './donate-bg.png';

function Layout({ colorType }) {
  return (
    <ClgLayout backgroundImage={bg} colorType={colorType}>
      <Header colorType={colorType} />
      <ClgLayout.Content colorType={colorType}>
        <Outlet />
      </ClgLayout.Content>
    </ClgLayout>
  );
}

Layout.propTypes = {
  colorType: PropTypes.string.isRequired,
};

export default Layout;
